<template>
    <v-data-table :height="height" fixed-header ref="table" :headers="headers" :items="items" :single-expand="false"
        :expanded="expanded" @update:expanded="val => $emit('update:expanded', val)" item-key="id" :show-expand="showExpand"
        dense class="" mobile-breakpoint="0" :options="options" @update:options="val => $emit('update:options', val)"
        :server-items-length="serverItemsLength" :loading="loading" :item-class="itemRowBackground"
        @item-expanded="({ item, value }) => $emit('item-expanded', { item, value })" :items-per-page="itemsPerPage"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 50] }" loading-text="Bezig met ophalen van de events...">
        <template v-slot:progress>
            <v-progress-linear indeterminate></v-progress-linear>
        </template>

        <template v-slot:footer.prepend>
            <slot name="footer.prepend"></slot>
        </template>

        <template v-slot:item.id="{ item }">
            <v-btn icon small @click="$emit('editItem', item)"><v-icon color="gray"
                    size="18">mdi-pencil-outline</v-icon></v-btn>
        </template>

        <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
            <template v-if="item.groupCount && item.groupCount > 1">
                <v-btn icon small @click="expand(true)" v-if="!isExpanded">
                    <v-icon color="gray">mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn icon small @click="expand(false)" v-if="isExpanded">
                    <v-icon color="secondary">mdi-chevron-down</v-icon>
                </v-btn>
            </template>
        </template>

        <template v-slot:item.time="{ item }">
            {{ $moment(item.time).format('DD/MM HH:mm') }}
        </template>
        <template v-slot:item.duration="{ item }">
            <template v-if="item.duration">
                {{ $moment(item.time).add(item.duration, 'seconds').format('DD/MM HH:mm') }}
            </template>
            <template v-else>
                -
            </template>
        </template>
        <template v-slot:item.value="{ item }">
            {{ item.measurementType ? getValueResolved(item.measurementType.id,
                item.value) : item.value }}
        </template>

        <template v-slot:item.type="{ item }">
            <v-icon size="18" :title="item.type">{{
                Event.getType(item.type) ? Event.getType(item.type).icon : ''
            }}</v-icon>
        </template>

        <template v-slot:item.severity="{ item }">
            <v-chip small :color="Event.getSeverity(item.severity) ? Event.getSeverity(item.severity).color : 'default'"
                dark>
                {{ Event.getSeverity(item.severity) ? Event.getSeverity(item.severity).text : item.severity }}
            </v-chip>
        </template>

        <template v-slot:item.facility="{ item }">
            {{ Event.getFacility(item.facility) ?
                Event.getFacility(item.facility).text : '' }}
        </template>

        <template v-slot:item.status="{ item }">
            <v-chip dark small :color="Event.getStatus(item.status).color">{{ Event.getStatus(item.status) ?
                Event.getStatus(item.status).text : '' }}</v-chip>
        </template>

        <template v-slot:item.comments="{ item }">
            <v-menu offset-y v-if="item.comments && item.comments.length > 0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small color="primary" dark v-bind="attrs" v-on="on" text>
                        <v-icon small>mdi-comment-processing-outline</v-icon>
                        <v-badge color="primary" :content="item.comments.length" inline></v-badge></v-badge>
                    </v-btn>
                </template>
                <v-card width="300">
                    <eod-comments v-model="item.comments" readonly flat image-field-name="storageObjects"></eod-comments>
                </v-card>
            </v-menu>
        </template>

        <template v-slot:item.images="{ item }">
            <v-icon left size="18">mdi-image</v-icon>{{ item.images ? item.images.length : 0 }}
        </template>

        <template v-slot:item.project="{ item }">
            <v-icon left size="18">mdi-folder-open-outline</v-icon>{{ item.project ? item.project.name : '' }}
        </template>

        <template v-slot:item.company="{ item }">
            <v-icon left size="18">mdi-office-building-outline</v-icon>{{ item.company ? item.company.name : '' }}
        </template>

        <template v-slot:item.usedProducts="{ item }">
            <v-icon left size="18">mdi-water-pump</v-icon>{{ item.usedProducts ? item.usedProducts.length : 0 }}
        </template>

        <!-- Expanded -->
        <template v-slot:expanded-item="{ headers, item }">

            <td v-for="header in headers" :key="header.value" class="text-start" style="padding:0;">
                    <template v-if="header.value == 'id'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                            <v-btn icon small @click="$emit('editItem', subevent)"><v-icon color="primary"
                                size="18">mdi-pencil-outline</v-icon></v-btn>
                        </div>
                    </template>

                    <template v-else-if="header.value == 'time'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        {{ $moment(subevent[header.value]).format('DD/MM HH:mm') }}
                    </div>
                    </template>
                    <template v-else-if="header.value == 'duration'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        <template v-if="subevent.duration">
                            {{ $moment(subevent.time).add(subevent[header.value],
                                'seconds').format('DD/MM HH:mm') }}
                        </template>
                        <template v-else>
                            -
                        </template>
                    </div>

                    </template>

                    <template v-else-if="header.value == 'value'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        {{ subevent.measurementType ? getValueResolved(subevent.measurementType.id,
                            subevent.value) : subevent[header.value] }}
                            </div>
                    </template>

                    <template v-else-if="header.value == 'type'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        <v-icon size="20" :title="subevent[header.value]">{{
                            Event.getType(subevent[header.value]) ? Event.getType(subevent[header.value]).icon : ''
                        }}</v-icon>
                        </div>
                    </template>

                    <template v-else-if="header.value == 'severity'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        <v-chip small
                            :color="Event.getSeverity(subevent[header.value]) ? Event.getSeverity(subevent[header.value]).color : 'default'"
                            dark>
                            {{ Event.getSeverity(subevent[header.value]) ? Event.getSeverity(subevent[header.value]).text :
                                subevent[header.value] }}
                        </v-chip>
                        </div>
                    </template>

                    <template v-else-if="header.value == 'status'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        <v-chip small :color="Event.getStatus(subevent[header.value]).color">{{
                            Event.getStatus(subevent[header.value]) ?
                            Event.getStatus(subevent[header.value]).text : '' }}</v-chip>
                            </div>
                    </template>

                    <template v-else-if="header.value == 'comments'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                            <v-menu offset-y v-if="subevent.comments && subevent.comments.length > 0">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small color="primary" dark v-bind="attrs" v-on="on" text>
                                        <v-icon small>mdi-comment-processing-outline</v-icon>
                                        <v-badge color="primary" :content="subevent.comments.length" inline></v-badge></v-badge>
                                    </v-btn>
                                </template>
                                <v-card width="300">
                                    <eod-comments v-model="subevent.comments" readonly flat image-field-name="storageObjects"></eod-comments>
                                </v-card>
                            </v-menu>
                        </div>
                    </template>

                    <template v-else-if="header.value == 'images'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        <v-icon left size="18">mdi-image</v-icon>{{
                            subevent[header.value] ? subevent[header.value].length : 0
                        }}
                        </div>
                    </template>

                    <template v-else-if="header.value == 'project'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        <v-icon left size="18">mdi-folder-open-outline</v-icon>{{ subevent.project.name ?
                            subevent.project.name : ''
                        }}</div>
                    </template>

                    <template v-else-if="header.value == 'company'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        <v-icon left size="18">mdi-office-building-outline</v-icon>{{ subevent.company.name ?
                            subevent.company.name
                            :
                            '' }}</div>
                    </template>

                    <template v-else-if="header.value == 'usedProducts'">
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        <v-icon left size="18">mdi-water-pump</v-icon>{{
                            subevent[header.value] ?
                            subevent[header.value].length : 0
                        }}</div>
                    </template>

                    <template v-else>
                        <div class="subevent-row" :class="itemRowBackground(subevent)" v-for="subevent in item.children" :key="subevent.id+'-'+header.value">
                        {{ $helper.getValueByDotName(subevent, header.value) }}</div>
                    </template>
                </td>
            
            <tr v-for="subevent in item.children" :key="subevent.id"
                class="v-data-table__expanded v-data-table__expanded--child" :class="itemRowBackground(subevent)">
                
            </tr>
        </template>
    </v-data-table>
</template>
<style lang="scss">
.bg-severity-crit {
    background-color: #ffe8e8 !important;
}

.bg-severity-ok {
    background-color: #f0fcf0 !important;
}

.bg-severity-info {
    background-color: #dfe8f0 !important;
}

.bg-severity-alert {
    background-color: #fff5e8 !important;
}

.bg-status-masked {
    background-color: #9b2fae26 !important;
    opacity: 0.6;
}
</style>
<style lang="scss" scoped>
.v-data-table {
    height: 100%;
}

.subevent-row{
    padding: 0 16px;
    height:32px;
    display: flex;
    align-items: center;

    border-bottom: 1px solid #dddddd;
}
</style>
<script>
import Event from './../models/event';
import MeasurementType from './../models/measurementType';
import eodDialog from './eod-dialog.vue';
import eodEvent from './eod-event.vue';
import eodComments from './eod-comments.vue';

export default {
    name: 'eod-events-table',
    components: {
        eodDialog,
        eodEvent,
        eodComments
    },
    props: {
        itemsPerPage: {
            type: Number,
            default: 10
        },
        loading: {
            type: Boolean,
            default: false
        },
        showExpand: {
            type: Boolean,
            default: true
        },
        serverItemsLength: {
            type: Number,
            default: 0
        },
        height: {
            type: String | Number,
            default: '100%'
        },
        headers: {
            type: Array,
            default: () => ([])
        },
        items: {
            type: Array,
            default: () => ([])
        },
        expanded: {
            type: Array,
            default: () => ([])
        },
        options: {
            type: Object,
            default: () => ({
                sortBy: ['time'],
                sortDesc: [true]
            })
        }
    },
    watch: {
        items() {
            this.init();
        }
    },
    data() {
        return {
            singleExpand: false,
            Event: Event,
            measurementTypeListItems: {}
        }
    },
    methods: {
        itemRowBackground: function (item) {
            return item.status == 'masked' || (item.alarmTemplate && item.alarmTemplate.content && item.alarmTemplate.content.is_masked)?'bg-status-masked':(item.severity ? 'bg-severity-' + item.severity : '');
        },
        getValueResolved(measurementTypeId, value) {

            if (this.measurementTypeListItems[measurementTypeId]) {
                for (let i = 0; i < this.measurementTypeListItems[measurementTypeId].length; i++) {
                    const listItem = this.measurementTypeListItems[measurementTypeId][i];
                    if (listItem.threshold == value) {
                        return listItem.name;
                    }
                }
            }

            return value;
        },
        async init() {
            const measurementTypeIds = [];
            if (this.items && this.items.length > 0) {
                for (let i = 0; i < this.items.length; i++) {
                    const item = this.items[i];
                    if (item.measurementType && !measurementTypeIds.includes(item.measurementType.id) && !Object.keys(this.measurementTypeListItems).includes(item.measurementType.id)) {
                        measurementTypeIds.push(item.measurementType.id);
                    }
                }
            }

            await this.$eod.get('measurementTypes', ['id', 'name', 'valueConfigList{id name itemType listItems{id threshold name color}}', 'valueConfigListItems{id threshold name color}', 'formConfig{category type}'], {
                whereIn: {
                    column: 'id',
                    array: measurementTypeIds
                }
            })
                .then(response => {
                    if (response.data.data.measurementTypes) {
                        for (let i = 0; i < response.data.data.measurementTypes.edges.length; i++) {
                            const measurementType = new MeasurementType(response.data.data.measurementTypes.edges[i]);
                            if (measurementType.formConfig.type == 'yesno') {
                                this.measurementTypeListItems[measurementType.id] = [{ threshold: 0, name: 'Nee' }, { threshold: 1, name: 'Ja' }];
                            } else {
                                this.measurementTypeListItems[measurementType.id] = measurementType.getListItems();
                            }
                        }
                    }
                });

            this.$forceUpdate();
        }
    },
}
</script>
  
<style lang="scss">
// Cutoff long cells
.cutoff-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;
}

// Keep cell in 1 line 
.v-data-table td,
.v-data-table th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Restyle "group by" column button
.v-data-table-header th.sortable span:last-child:not(.v-data-table-header__sort-badge) {
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    font-size: 0 !important;
    background-color: transparent;
    border: 2px solid var(--v-primary-base);
    opacity: 0.4;

    &:hover {
        opacity: 1;
        border-color: var(--v-secondary-base);
    }
}

// Expanded row styling
.v-data-table__expanded--child {
    background-color: rgba(#031859, .05);
}

.v-data-table__expanded__row {
    color: #031859;
    box-shadow: 0px 5px 4px -5px rgba(0,0,0,1);
    position: relative;
    z-index: 5;
}


// Multiselect fix
.v-text-field__details,
.v-messages {
    min-height: 0;
}


// Remove margin left from pager
.v-application--is-ltr .v-data-footer__select {
    margin-left: 15px;
}
</style>