<template>
  <td>
    <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left"
      :disabled="item.statusCode == 'done' || isReadonly">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" :color="itemObj.getStatusCode().color" v-if="itemObj.getStatusCode()"
          :dark="itemObj.getStatusCode().textColor != 'dark'" x-small block elevation="0" rounded>
          <v-icon size="14" left>{{ itemObj.getStatusCode().icon }}</v-icon>
          {{ itemObj.getStatusCode().text }}
        </v-btn>
        <v-chip pill v-on="on" color="grey lighten-4" v-else>
          -
        </v-chip>
      </template>
      <v-card>
        <v-list>
          <v-list-item @click="setStatusCode(statusCode.value)" v-for="statusCode in getStatusCodes()"
            :key="statusCode.value">
            <div
              :style="'background-color:' + statusCode.color + ';width:8px;height:100%;left:0;top:0;position:absolute;'">
            </div>
            <v-list-item-action>
              <v-icon small>{{ statusCode.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-subtitle>{{ statusCode.text }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-dialog v-model="finishDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          Taak afwerken
        </v-card-title>
        <v-card-text>
          <strong>Weet u zeker dat u de taak wilt afwerken?</strong><br>
          De taak kan hierna geen antwoorden meer ontvangen of worden gewijzigd.

          <v-checkbox v-if="itemObj.service && itemObj.service.periodicityCode && itemObj.service.periodicityCode != ''"
            v-model="periodicityTask" label="Dezelfde taak aanmaken over 1 jaar"></v-checkbox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="finishDialog = false">
            Annuleren
          </v-btn>
          <v-btn color="success" @click="finishTask">
            Afwerken
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </td>
</template>
<script>
export default {
  name: 'eod-column-status-code',
  props: {
    item: Object,
    config: Object,
    extra: Object,
    readonly: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      settings: require('./../../classes/settings'),
      menu: false,
      finishDialog: false,
      periodicityTask: false,
      isReadonly: false
    }
  },
  computed: {
    itemObj() {
      return new (this.config.itemObject)(this.item);
    }
  },
  mounted() {
    if (this.readonly || (this.extra && this.extra.readonly)) {
      this.isReadonly = true;
    }
    if (this.$eod.getOrganizationSetting('tasks.periodicity.defaultEnabled')) {
      if (this.$eod.getOrganizationSetting('tasks.periodicity.defaultEnabled').value == true) {
        this.periodicityTask = true;
      }
    }
  },
  methods: {
    getStatusCodes() {
      if (this.extra && this.extra.getStatusCodes) {
        return this.extra.getStatusCodes(this.settings);
      }

      return this.settings.statusCodes;
    },
    finishTask() {
      this.changeStatus('done').then(() => {
        if (this.$eod.getOrganizationSetting('share.ftp.hosts.0.host')) {
          this.$eodShare.upload('ftp', 'task', {
            'path': this.$eod.getOrganizationSetting('share.ftp.tasks.path').value,
            'taskId': this.item.id
          });
        }

        if (this.periodicityTask) {
          this.$eod.getById('task', this.item.id, ['name', 'description', 'estimatedDuration', 'statusCode', 'dueRequestedDate', 'postalCode', 'dueBeforeDate', 'dueAfterDate', 'company{id}', 'taskType{id name}', 'service{id}', 'project{id}', 'storageObjects{ id }', 'products{id}', 'productTypes{id}', 'lat', 'lon']).then(response => {
            let task = new this.config.itemObject(response.data.data.task);
            let saveData = task.getSaveData();
            delete saveData.id;

            saveData.category = 'TASK';

            saveData.statusCode = "backlog";
            if (saveData.dueBeforeDate && saveData.dueAfterDate) {
              saveData.statusCode = "to_do";
              saveData.dueBeforeDate = this.$moment(saveData.dueBeforeDate).add('years', 1);
              saveData.dueAfterDate = this.$moment(saveData.dueAfterDate).add('years', 1);
            }

            let append = '';
            const externalId = this.$eod.getOrganizationSetting('tasks.external.id');
            if (externalId && externalId.value && externalId.value != '') {
              append = ' [' + externalId.value + ']';
              saveData.externalIds = [{
                'name': 'dbfact',
                'id': externalId.value
              }];
            }

            saveData.name = task.taskType.name + append;

            this.$eod.save(this.config.detail.objectName, saveData, false).then(response => {
              let new_task_id = response.data.data.createTask.id;

              if (externalId && externalId.value && externalId.value != '') {
                let nextId = parseInt(externalId.value) + 1;
                this.$eod.saveOrganizationSetting('tasks.external.id', nextId);
              }

              saveData.parentTaskId = new_task_id;
              saveData.category = 'JOB';

              this.$eod.save(this.config.detail.objectName, saveData, false).then(response => {
                this.$emit('update');
              });
            });
          })
        }
      }).finally(() => {
        this.finishDialog = false;
      });
    },
    setStatusCode(statusCode) {
      if (statusCode == 'done') {
        this.finishDialog = true;
      } else if (statusCode != this.item.statusCode) {
        this.changeStatus(statusCode);
      }
    },
    changeStatus(statusCode) {
      return this.$eod.save(this.config.detail.objectName, { id: this.item.id, statusCode: statusCode }).then(result => {
        this.item.statusCode = statusCode;
        this.$emit('changed', statusCode);
      });
    }
  }
}
</script>